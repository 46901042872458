.dashboard-layout {
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.dashboard-container {
  flex: 1;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  margin-left: 280px; /* Add space for sidebar */
}

.dashboard-header {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-header h1 {
  color: #111827;
  font-size: 1.875rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  margin: 0;
}

.user-info {
  color: #6b7280;
  font-size: 0.9375rem;
  font-weight: 500;
  background-color: white;
  padding: 0.75rem 1.25rem;
  border-radius: 9999px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.dashboard-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.getting-started-card {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.getting-started-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.getting-started-card h2 {
  color: #111827;
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.getting-started-card p {
  color: #4b5563;
  font-size: 1rem;
  line-height: 1.6;
  margin: 0 0 1.5rem 0;
}

.getting-started-card ul {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.getting-started-card li {
  margin: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.getting-started-card li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #0070f3;
}

.getting-started-card li {
  color: #374151;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  background-color: #f8fafc;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.getting-started-card li:hover {
  background-color: #f3f4f6;
}

.voice-recorder-section {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.voice-recorder-section h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.recordings-list {
  margin-top: 2rem;
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.recordings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

.recording-stats {
  display: none;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .dashboard-container {
    margin-left: 0;
    padding: 1rem;
    padding-top: 70px; /* Space for fixed mobile header */
  }

  .dashboard-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 1rem;
    margin: 0;
    z-index: 100;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
  }

  .dashboard-header h1 {
    font-size: 1.5rem;
  }

  .desktop-only {
    display: none;
  }

  .voice-recorder-section {
    padding: 1.5rem;
    margin: -1rem;
    margin-bottom: 1.5rem;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
  }

  .recording-stats {
    display: block;
    padding: 1.5rem;
    background: white;
    border-radius: 12px;
    margin-bottom: 1.5rem;
  }

  .mobile-stats {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .stat-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    background: #f8f9fa;
    border-radius: 8px;
  }

  .stat-label {
    color: #6b7280;
    font-size: 0.875rem;
  }

  .stat-value {
    color: #111827;
    font-weight: 500;
  }
}

/* Touch-friendly improvements */
@media (hover: none) and (pointer: coarse) {
  .getting-started-card {
    cursor: default;
    -webkit-tap-highlight-color: transparent;
  }

  button, 
  a {
    min-height: 44px;
    min-width: 44px;
    padding: 12px;
  }
}
