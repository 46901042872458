/* Base styles */
.home-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.home-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.nav-brand {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  text-decoration: none;
}

.nav-actions {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.nav-link {
  color: #333;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s;
}

.nav-link:hover {
  color: #007AFF;
}

.nav-button {
  background: #333;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.2s;
}

.nav-button:hover {
  background: #007AFF;
}

/* Hero Section */
.hero-section {
  margin-top: 80px; /* Account for fixed nav */
  padding: 4rem 2rem;
  text-align: center;
  background: #f8f9fa;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-content h1 {
  font-size: 3.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.hero-subtitle {
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 2.5rem;
  line-height: 1.6;
}

.hero-actions {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  align-items: center;
}

.cta-button {
  background: #007AFF;
  color: white;
  padding: 1rem 2rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.1rem;
  transition: transform 0.2s, background-color 0.2s;
}

.cta-button:hover {
  background: #0056b3;
  transform: translateY(-2px);
}

.learn-more {
  color: #666;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  transition: color 0.2s;
}

.learn-more:hover {
  color: #333;
}

.arrow {
  font-size: 1.2rem;
}

/* Features Section */
.features-section {
  padding: 5rem 2rem;
  background: white;
}

.features-section h2 {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 3rem;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.feature-card {
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  transition: transform 0.2s;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.feature-card h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.feature-card p {
  color: #666;
  line-height: 1.6;
}

/* Mobile-specific styles */
.mobile-only {
  display: none;
}

.desktop-only {
  display: flex;
}

/* Media queries */
@media (max-width: 768px) {
  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }

  .home-nav {
    padding: 1rem;
  }

  .hero-section {
    margin-top: 64px;
    padding: 3rem 1rem;
  }

  .hero-content h1 {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.1rem;
    padding: 0 1rem;
  }

  .hero-actions {
    flex-direction: column;
    gap: 1rem;
  }

  .features-section {
    padding: 3rem 1rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}

/* Touch device optimizations */
@media (hover: none) {
  .nav-link:hover,
  .nav-button:hover,
  .cta-button:hover,
  .feature-card:hover {
    transform: none;
  }
}
