.mobile-menu-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  max-width: 300px;
  height: 100%;
  background: white;
  z-index: 1001;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
}

.mobile-menu.open {
  transform: translateX(0);
}

.mobile-menu-content {
  padding: 2rem 1rem;
  height: 100%;
  overflow-y: auto;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;
}

.mobile-nav-link {
  font-size: 1.2rem;
  color: #333;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: background-color 0.2s;
}

.mobile-nav-link:hover {
  background-color: #f5f5f5;
}

.mobile-nav-link.highlight {
  background-color: #007AFF;
  color: white;
}

.mobile-nav-link.highlight:hover {
  background-color: #0056b3;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
