.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1001;
}

.hamburger:focus {
  outline: none;
}

.hamburger-line {
  width: 2rem;
  height: 0.25rem;
  background: #333;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.hamburger.open .hamburger-line:first-child {
  transform: rotate(45deg);
}

.hamburger.open .hamburger-line:nth-child(2) {
  opacity: 0;
  transform: translateX(20px);
}

.hamburger.open .hamburger-line:nth-child(3) {
  transform: rotate(-45deg);
}
