.nav-container {
  width: 280px;
  min-width: 280px;
  height: 100vh;
  background-color: white;
  border-right: 1px solid #e5e7eb;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  transition: transform 0.3s ease;
}

.nav-header {
  padding: 2rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-header h1 {
  color: #111827;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}

.nav-links {
  flex: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  color: #4b5563;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.nav-link:hover {
  background-color: #f3f4f6;
  color: #111827;
}

.nav-link.active {
  background-color: #f3f4f6;
  color: #111827;
  font-weight: 500;
}

.sign-out-button {
  width: 100%;
  padding: 0.75rem;
  margin-top: 0.5rem;
  background: #ef4444;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.sign-out-button:hover {
  background: #dc2626;
}

.mobile-user-info {
  display: none;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.user-email {
  color: #6b7280;
  font-size: 0.875rem;
  font-weight: 500;
}

.mobile-menu-button {
  display: none;
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
  background: white;
  border: none;
  border-radius: 8px;
  padding: 0.5rem;
  color: #111827;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.nav-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 90;
}

.close-menu-button {
  display: none;
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  padding: 0.5rem;
}

/* Adjust main content to account for navigation */
.main-content {
  margin-left: 280px;
  padding: 2rem;
  min-height: 100vh;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .mobile-menu-button {
    display: block;
  }

  .close-menu-button {
    display: block;
  }

  .nav-overlay {
    display: block;
  }

  .nav-container {
    transform: translateX(-100%);
    height: 100%;
    max-height: 100vh;
  }

  .nav-container.nav-open {
    transform: translateX(0);
  }

  .mobile-user-info {
    display: block;
  }

  .nav-header {
    padding: 1rem;
  }

  .nav-links {
    padding: 1rem;
    overflow-y: auto;
  }

  .nav-link {
    padding: 1rem;
  }

  .sign-out-button {
    margin-top: 1rem;
  }
}
