.voice-recorder {
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.controls {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.record-button {
  position: relative;
  padding: 1rem 2rem;
  font-size: 1rem;
  border: none;
  border-radius: 50px;
  background: #f0f0f0;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;
}

.record-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.record-button.recording {
  background: #ff4444;
  color: white;
  animation: pulse 2s infinite;
}

.recording-time {
  font-family: monospace;
  font-size: 0.9rem;
  padding: 0.2rem 0.5rem;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 68, 68, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 68, 68, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 68, 68, 0);
  }
}

.audio-playback, .recordings-list {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  text-align: center;
}

.audio-playback h3, .recordings-list h3 {
  margin-top: 0;
  color: #333;
  margin-bottom: 1rem;
}

.audio-playback {
  margin: 2rem 0;
  padding: 1rem;
  background: #f8f8f8;
  border-radius: 8px;
}

.recordings-list {
  margin-top: 2rem;
}

.recordings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.recording-item {
  background: #ffffff;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.recording-item:hover {
  transform: translateY(-2px);
}

.recording-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.recording-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
}

.recording-title {
  font-weight: 600;
  color: #333;
  font-size: 1.1rem;
}

.recording-date {
  color: #666;
  font-size: 0.9rem;
  font-weight: normal;
}

.recording-duration {
  color: #666;
  font-size: 0.9rem;
}

.recording-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.delete-button {
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.delete-button:hover {
  background-color: #ff0000;
}

.delete-button:disabled {
  background-color: #ffaaaa;
  cursor: not-allowed;
}

.transcription {
  background: #f8f9fa;
  padding: 12px;
  border-radius: 6px;
  margin-top: 12px;
}

.transcription h4 {
  margin: 0 0 8px 0;
  color: #444;
  font-size: 0.9rem;
}

.transcription p {
  margin: 0;
  color: #666;
  font-size: 0.9rem;
  line-height: 1.4;
  white-space: pre-wrap;
}

.no-transcription {
  color: #666;
  font-style: italic;
  padding: 0.5rem;
  background: #f5f5f5;
  border-radius: 4px;
  margin-top: 0.5rem;
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  color: #666;
}

.loading-indicator p {
  margin: 0;
  padding: 0.5rem;
  background-color: #f5f5f5;
  border-radius: 4px;
  font-size: 0.9rem;
}

audio {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 0.5rem;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .voice-recorder {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
    box-shadow: none;
  }

  .controls {
    margin: 2rem 0;
    width: 100%;
  }

  .record-button {
    width: 160px;
    height: 160px;
    padding: 0;
    border-radius: 50%;
    font-size: 1.25rem;
    font-weight: 600;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    background: white;
  }

  .record-button:not(.recording) {
    background: #ff4444;
    color: white;
  }

  .record-button.recording {
    background: #dc2626;
    animation: mobilePulse 2s infinite;
  }

  .recording-time {
    font-size: 1.125rem;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
    background: rgba(255, 255, 255, 0.2);
  }

  @keyframes mobilePulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 68, 68, 0.4);
    }
    70% {
      box-shadow: 0 0 0 20px rgba(255, 68, 68, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 68, 68, 0);
    }
  }
}
