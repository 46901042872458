.profile-container {
  flex: 1;
  padding: 2.5rem 3rem;
  margin-left: 280px;
  background-color: #f8fafc;
  min-height: 100vh;
}

.profile-header {
  margin-bottom: 2.5rem;
}

.profile-header h1 {
  color: #111827;
  font-size: 1.875rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  margin: 0;
}

.profile-content {
  max-width: 768px;
  background: white;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.profile-section {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #e5e7eb;
}

.profile-section:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.profile-section h2 {
  color: #111827;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0 0 1.5rem 0;
}

.profile-section h2 {
  color: #2c3e50;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #e2e8f0;
  padding-bottom: 0.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group:last-child {
  margin-bottom: 0;
}

.form-label {
  display: block;
  color: #374151;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.form-input {
  width: 100%;
  padding: 0.75rem;
  font-size: 0.9375rem;
  color: #111827;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.form-input:focus {
  outline: none;
  border-color: #404040;
  box-shadow: 0 0 0 2px rgba(64, 64, 64, 0.1);
}

.form-input:disabled {
  background-color: #f3f4f6;
  cursor: not-allowed;
}

.form-input.error {
  border-color: #ff4d4f;
}

.error-text {
  color: #ff4d4f;
  font-size: 0.875rem;
  margin-top: 4px;
}

.character-count {
  color: #8c8c8c;
  font-size: 0.875rem;
  text-align: right;
  margin-top: 4px;
}

.save-button {
  padding: 0.75rem 1.5rem;
  font-size: 0.9375rem;
  font-weight: 500;
  color: white;
  background-color: #404040;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.save-button:hover {
  background-color: #333333;
}

.save-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.loading-spinner {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.message {
  margin-bottom: 1rem;
  padding: 0.75rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}

.message.success {
  background-color: #dcfce7;
  color: #166534;
}

.message.error {
  background-color: #fee2e2;
  color: #991b1b;
}

/* Toast Customization */
.toast {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  font-size: 0.875rem;
  max-width: 350px;
}

.toast-success {
  background-color: #52c41a;
  color: white;
}

.toast-error {
  background-color: #ff4d4f;
  color: white;
}

.toast-loading {
  background-color: #1890ff;
  color: white;
}

.interests-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 12px;
  margin-top: 8px;
}

.interest-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.interest-checkbox:hover {
  background-color: #e8e8e8;
}

.interest-checkbox input[type="checkbox"] {
  margin: 0;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  background: #e2e8f0;
  border-radius: 3px;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  background: #3182ce;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.2s;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: #2c5282;
}

select.form-input {
  padding-right: 2rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' stroke='%232c3e50' stroke-width='2'%3E%3Cpath d='M4 6l4 4 4-4'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-group span {
  display: inline-block;
  min-width: 45px;
  text-align: right;
  margin-left: 12px;
  color: #4a5568;
  font-size: 0.875rem;
}

.profile-section {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .form-group-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

.help-text {
  font-size: 0.875rem;
  color: #718096;
  margin-top: 4px;
}

select:disabled,
input:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: #f7fafc;
}
